<template>
    <span class="text-danger cursor-pointer" v-b-tooltip.hover="'Supprimer'">
        <font-awesome-icon :icon="['far', 'trash-alt']"/>
    </span>
</template>

<script>
    export default {
        name: "TextDeleteButton"
    }
</script>

<style scoped>
    .cursor-pointer.text-danger:hover {
        color: #d73455 !important;
    }
</style>